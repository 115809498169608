import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Card, CardContent, CardMedia, Chip, Typography, } from '@mui/material';
import theme from '../../Theming/DefaultTheme';
import { useNavigate } from 'react-router-dom';
import AppButton from '../buttons/AppButton';
var cardStyles = {
    borderRadius: 12,
    position: 'relative',
    overflow: 'hidden',
    border: '1px solid #ADD8E6',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 8px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1)',
    transition: 'transform 0.3s ease-in-out',
};
var cardHoverStyles = {
    transform: 'scale(1.01)',
};
var overlayStyles = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: 12,
};
var buttonStyles = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    borderRadius: 20,
    backgroundColor: '#FF7F50',
};
var chipStyles = {
    margin: '4px 4px 0 0',
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
};
var ProjectCard = function (_a) {
    var project = _a.project;
    var navigate = useNavigate();
    var handleNavigate = function (path, hash) {
        if (path.startsWith('http')) {
            window.location.href = path;
        }
        else {
            navigate(path);
            if (hash) {
                window.location.hash = hash;
            }
        }
    };
    return (_jsxs(Card, { style: cardStyles, onMouseEnter: function (e) {
            return (e.currentTarget.style.transform =
                cardHoverStyles.transform || 'scale(1.01)');
        }, onMouseLeave: function (e) { return (e.currentTarget.style.transform = 'scale(1)'); }, children: [_jsx(CardMedia, { component: 'img', height: '450', image: project.image, alt: project.title }), project.link && (_jsx(Box, { style: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                }, children: _jsx(AppButton, { buttonText: 'Check It Out', textColor: theme.palette.common.white, styles: buttonStyles, onClick: function () { return handleNavigate(project.link); } }) })), _jsxs(Box, { style: overlayStyles, children: [_jsxs(CardContent, { style: {
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                        }, children: [_jsx(Typography, { variant: 'h4', mb: 1, style: { textDecoration: 'underline', fontSize: '1rem' }, children: project.title }), _jsx(Typography, { variant: 'caption', children: project.description })] }), project.tags.length > 0 && (_jsx(_Fragment, { children: project.tags.map(function (tag, index) { return (_jsx(Chip, { style: chipStyles, label: tag }, "technologies-chip-".concat(index))); }) }))] })] }));
};
export default ProjectCard;
