import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Box, Divider, useMediaQuery, useTheme, } from '@mui/material';
import { getPageSectionStyle } from '../../Theming/globalStyles';
import Grid from '@mui/material/Grid2';
import ProjectCard from '../cards/ProjectCard';
import { PROJECTS } from '../../../constants';
var dividerStyle = {
    width: 120,
    margin: '0px 0px 10px 0px',
};
var sectionStyling = {
    background: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
};
var ProjectsSection = function () {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsxs(Box, { style: getPageSectionStyle(isMobile, sectionStyling), children: [_jsx(Typography, { variant: 'h3', component: 'h2', style: { marginBottom: 10 }, children: "Key Career Projects" }), _jsx(Divider, { style: dividerStyle }), _jsx(Box, { style: { maxWidth: '60vw' }, children: _jsx(Typography, { variant: 'body1', style: { marginBottom: 60, textAlign: 'center' }, children: "I love what I get to do every day as an enginer. I am always looking for opportunities to learn and grow as well as make an impact. These are some of the key projects, personal and professional, that I have had the opportunity to work on." }) }), _jsx(Box, { children: _jsx(Grid, { container: true, spacing: 3, mb: 2, children: PROJECTS.map(function (project, index) {
                        return (_jsx(Grid, { size: { lg: 4, xs: 12 }, children: _jsx(ProjectCard, { project: project }) }, index));
                    }) }) })] }));
};
export default ProjectsSection;
