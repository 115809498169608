var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { convertMillisecondsToSeconds } from '../../utils';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import TextLink from './TextLink';
import theme from '../Theming/DefaultTheme';
var footerStyle = {
    color: '#fff',
    padding: '20px 40px 20px 10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    bottom: 0, // Ensures the footer stays at the bottom
    backgroundColor: theme.palette.common.black,
};
var inlineTextBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
};
var footerTextColumnLeft = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    height: '100%',
};
var footerTextColumn = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
};
var footerLinkStyle = {
    textDecoration: 'none',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s',
};
var Footer = function () {
    var navigate = useNavigate();
    var _a = useState({
        loadTime: 0,
    }), componentState = _a[0], setComponentState = _a[1];
    var updateComponentState = function (newComponentState) {
        setComponentState(function (componentState) {
            return __assign(__assign({}, componentState), newComponentState);
        });
    };
    useEffect(function () {
        var loadTime = performance.now();
        updateComponentState({ loadTime: loadTime });
    }, []);
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
    };
    return (_jsx("footer", { style: footerStyle, children: _jsxs(Grid, { container: true, spacing: 2, width: '100%', children: [_jsx(Grid, { size: { lg: 5, xs: 12 }, children: _jsxs(Box, { style: footerTextColumnLeft, children: [_jsxs(Typography, { variant: 'body2', children: ["\u00A9 ", new Date().getFullYear(), " Evan Ellison"] }), _jsx(Box, { style: inlineTextBoxStyle, children: componentState.loadTime !== null && (_jsxs(Typography, { variant: 'caption', children: ["Page load", ' ', convertMillisecondsToSeconds(componentState.loadTime), ' ', "seconds"] })) })] }) }), _jsx(Grid, { size: { lg: 7, xs: 10 }, children: _jsxs(Grid, { container: true, spacing: 2, width: '100%', justifyContent: 'space-between', children: [_jsx(Grid, { size: { lg: 4, xs: 4 }, children: _jsxs(Box, { style: footerTextColumn, children: [_jsx(Typography, { variant: 'body1', children: "Get In Touch" }), _jsxs("ul", { style: { listStyleType: 'none', padding: 0, margin: 0 }, children: [_jsx("li", { children: _jsx(Typography, { variant: 'caption', children: _jsxs("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/', '#faq'); }, children: ["Email:", ' ', _jsx(TextLink, { onClick: function () {
                                                                        return window.open('mailto:EvanEllison@codefronttechnologies.com');
                                                                    }, children: "EvanEllison@codefronttechnologies.com" })] }) }) }), _jsx("li", { children: _jsx(Typography, { variant: 'caption', children: _jsxs("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/', '#token-counter'); }, children: ["LinkedIn:", ' ', _jsx(TextLink, { onClick: function () {
                                                                        return window.open('https://www.linkedin.com/in/evan-michael-ellison-93a5776b/');
                                                                    }, children: "Evan Ellison" })] }) }) }), _jsx("li", { children: _jsx(Typography, { variant: 'caption', children: _jsxs("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/', '#token-counter'); }, children: ["GitHub:", ' ', _jsx(TextLink, { onClick: function () {
                                                                        return window.open('https://github.com/EvanMEllison');
                                                                    }, children: "Github Profile" })] }) }) })] })] }) }), _jsx(Grid, { size: { lg: 4, xs: 4 }, children: _jsxs(Box, { style: footerTextColumn, children: [_jsx(Typography, { variant: 'body1', children: "Site Map" }), _jsx("ul", { style: { listStyleType: 'none', padding: 0, margin: 0 }, children: _jsx("li", { children: _jsx(Typography, { variant: 'caption', children: _jsx("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/home, #home'); }, children: "Home" }) }) }) })] }) }), _jsx(Grid, { size: { lg: 4, xs: 4 }, children: _jsxs(Box, { style: footerTextColumn, children: [_jsx(Typography, { variant: 'body1', children: "Our Mission" }), _jsxs("ul", { style: { listStyleType: 'none', padding: 0, margin: 0 }, children: [_jsx("li", { children: _jsx(Typography, { variant: 'caption', children: _jsx("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/', '#blog'); }, children: "Blog" }) }) }), _jsx("li", { children: _jsx(Typography, { variant: 'caption', children: _jsx("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/', '#projects'); }, children: "Current Projects" }) }) })] })] }) })] }) })] }) }));
};
export default Footer;
